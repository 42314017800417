<template>
  <div class="page-content detailed" v-if="content">

    <detail-banner :content="content" :title="title" :categories="readAbleCategories" :slides="slides"/>

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12 pr-lg-3">

          <div class="row mb-3">
            <div class="col-12">
              <ul class="info--list" id="horizontal-style">
                <li class="info-list">
                  <div class="info-list__icon">
                    <span class="material-icons">room</span>
                  </div>
                  <div class="info-list__content">
                    <div>{{street}}</div>
                    <div>{{city}}</div>
                    <button v-if="!showMap" @click="toggleMap" class="btn btn-link">Auf der Karte anzeigen</button>
                    <button v-else @click="toggleMap" class="btn btn-link">Karte verbergen</button>
                  </div>
                </li>
                <li class="info-list">
                  <div class="info-list__icon">
                    <span class="material-icons">schedule</span>
                  </div>
                  <div class="info-list__content">
                    <appointment :appointments="appointments"/>
                    <button v-show="appointments!=null" v-if="!showAppointments" @click="toggleAppointments" class="btn btn-link">Zeige weitere Termine</button>
                    <button v-show="appointments!=null" v-else @click="toggleAppointments" class="btn btn-link">Termine verbergen</button>
                  </div>
                </li>
                <li class="info-list" v-if="url">
                  <div class="info-list__icon">
                    <span class="material-icons">home</span>
                  </div>
                  <div class="info-list__content" >
                    <a :href="url" target="_blank">{{shortenedUrl}}</a>
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <div class="row content-wrapper">

            <div v-show="showMap" :class="contentClass">
              <div class="wrapper map-wrapper">
                <h1 class="section-title">Karte</h1>
                <the-map class="map" :address-obj="addressObj"></the-map>
              </div>
            </div>

            <div v-show="showAppointments" :class="contentClass">
              <div class="wrapper appointment-wrapper">
                <h1 class="section-title">Termine</h1>
                <div class="info-list__content--dropdown">
                  <appointments-list :appointments="appointments" :active="true"/>
                </div>
              </div>
            </div>

            <div :class="contentClass">
              <div class="wrapper text-wrapper">
                <h1 class="section-title" v-html="title"></h1>
                <div v-html="description"></div>
              </div>
            </div>
          </div>

          <div v-if="contentDataSet.data!==null && contentDataSet.data.length>0 && institution.content_type_name!=='pois'" class="row">
            <div class="col-12">
              <card-slider :data="contentDataSet.data" title="Angebote dieser Location" card="event" />
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-if="similarInstitutions!=null && similarInstitutions.length>0">
              <card-slider :data="similarInstitutions" title="Highlights in der Nähe" card="location" />
            </div>
            <collection-block v-if="content!==null" :content="content"/>
          </div>
        </div>
        <div class="col-12 col-lg-3 pl-lg-3" style="display:none;">
          <div class="reactions mt-3 mt-md-0">
            <h6 class="title">Reaktionen</h6>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="reactions-list__info">
                  <h6>VikingFan0815</h6>
                  <p>Klasse Museum, kann ich jedem Wikingerfan empfehlen! Habe mich fast selbst wie ein Wikinger gefühlt ;-)</p>
                </div>
              </div>
            </div>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="form-group mb-0">
                  <textarea name="reaction" class="form-control" id="" placeholder="Füge eine Reaktion hinzu ..." cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides';
  import 'vueperslides/dist/vueperslides.css';
  import TheMap from '@/components/map/single.vue';
  import Resource from '@/api/resource';
  import Appointment from '@/components/appointments/appointments.vue';
  import AppointmentsList from '@/components/appointments/appointmentsDropdownContents.vue';
  const contentResource = new Resource('contents');
  import { getFieldValues, showLoader, hideLoader, json_decode } from '@/utils/helpers';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';
  import { getContentsOfType } from '@/api/content';
  import { latLng } from 'leaflet';
  import { getInstitutionContents } from '@/api/institution';

  export default {
    name: 'LocationView',
    components: {
      TheMap,
      CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
      CardSlider: () => import('@/components/CardSlider'),
      DetailBanner: () => import('@/components/DetailBanner'),
      ShareButton: () => import('@/components/controls/ShareButton'),
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
      CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
      Appointment,
      AppointmentsList,
      VueperSlides,
      VueperSlide
    },
    data() {
      return {
        institution: null,
        showMap: true,
        showAppointments: false,
        similarInstitutionsDataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 6,
            type: 'view',
            keyword: this.category,
            selectedAppId: this.$appId,
            selectRandomly: true,
            sort: {
              prop: '',
              order: 'asc'
            },
          },
        },
        contentDataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 6,
            type: 'view',
            keyword: '',
            selectedAppId: this.$appId,
            selectedContentTypeId: this.$offerId,
            view_status: 1,
            sort: {
              prop: '',
              order: 'asc'
            },
          },
        },
        reiseplaene: [
          { image: '/assets/highlights/thumbnails/gastro.jpg', name: 'Surfen in SH', info: '14 Destinationen' },
          { image: '/assets/highlights/thumbnails/gastro.jpg', name: 'Surfen in SH', info: '14 Destinationen' },
          { image: '/assets/highlights/thumbnails/kultur.jpg', name: 'Musik und Bars', info: '25 Destinationen' },
          { image: '/assets/highlights/thumbnails/shopping.jpg', name: 'Noch ein Reiseplan', info: 'Kultur, Sightseeing' },
          { image: '/assets/highlights/thumbnails/wellness.jpg', name: 'Und noch ein Reiseplan', info: 'Kultur, Sightseeing' }
          ]
      }
    },
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getInstitution();
          //this.isActiveLocation=true;
          //this.scrollToTop();
        }
      }
    },
    created() {
      this.getInstitution();
      //this.isActiveLocation=true;
    },
    computed: {
      content(){
        if(this.institution!==null){
          return this.institution;
        }
        return null;
      },
      similarInstitutions(){
        if(this.similarInstitutionsDataSet.data!=null && this.similarInstitutionsDataSet.data.length>0){
          var similar = [];
          for(var i=0;i<this.similarInstitutionsDataSet.data.length;i++){
            if(this.similarInstitutionsDataSet.data[i].id != this.content.id){
              similar.push(this.similarInstitutionsDataSet.data[i]);
            }
            /*we need only five*/
            if(similar.length==5){
              return similar;
            }
          }
          return similar;
        }
        return null;
      },
      contentClass(){
        if(this.showAppointments || this.showMap){
          return "col-12 col-lg-6";
        }
        return "col-12";
      },
      id() {
        return this.$route.params.id;
      },
      title(){
        if(this.content!=null){
          return this.content.title;
        }
        return "";
      },
      categories(){
        return getFieldValues(this.content,'kategorien');
      },
      category(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories[0];
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      readAbleCategories(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories.join(", ");
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      addressObj(){
        return getFieldValues(this.content,'adresse');
      },
      street(){
        if(this.addressObj!=null){
          return this.addressObj.street;
        }
        return '';
      },
      city(){
        if(this.addressObj!=null){
          return this.addressObj.zipcode+' '+this.addressObj.city;
        }
        return '';
      },
      coords(){
        if(this.addressObj!=null && this.addressObj.latitude!=null && this.addressObj.longitude!=null){
          return latLng(this.addressObj.latitude,this.addressObj.longitude);
        }
        return null;
      },
      appointments(){
        return getFieldValues(this.content,'appointments');
      },
      description(){
        var desc = getFieldValues(this.content,'description');
        return desc!=null?desc:'';
      },
      teaserImages(){
        var pictures = getFieldValues(this.content,'teaser-bild');
        if(pictures != null){
          if(Array.isArray(pictures)){
            return pictures;
          }
          else{
            return [pictures];
          }
        }
        return null;
      },
      logo(){
        var logo = getFieldValues(this.content,'logo');
        return json_decode(logo);
      },
      url(){
        return getFieldValues(this.content,'url');
      },
      shortenedUrl(){
        if(this.url!=null){
          return this.url.length>26?this.url.substring(0,26)+' ...':this.url;
        }
      },
      currentPath(){
        return window.location.href;
      },
      shareViaEmail(){
        return "mailto:?body="+this.currentPath;
      },
      slides(){
        var images = [];
        if(this.logo != null){
          var logo = json_decode(this.logo);
          if(typeof logo === "object"){
            if(logo.path.indexOf('https')!==-1){
              images.push({image:logo.path,class:'logo'});
            }
            else{
              images.push({image:this.$backendUrl+logo.path,class:'logo'});
            }
          }
          else {
            images.push({image:this.$backendUrl+logo,class:'logo'});
          }
        }

        if(this.teaserImages != null){
          for(var i = 0; i < this.teaserImages.length; i++){
            var image = json_decode(this.teaserImages[i]);

            if(typeof image === "object"){
              if(image.path.indexOf('https')!==-1){
                images.push({image:image.path,class:'logo'});
              }
              else{
                images.push({image:this.$backendUrl+image.path,class:'logo'});
              }        
            }
            else{
              images.push({image: this.$backendUrl+image,class:'teaser-img'});
            }
          }
        }

        /*add the logo as the first image to the array*/
        if(images.length == 0){
          return [{image: this.getCategoryPlaceholderImage(this.category),class:'teaser-img'}];
        }
        return images;
      },
    },
    methods:{
     getFieldValues, 
     getCategoryPlaceholderImage,
     showLoader, 
     hideLoader,
     getInstitution() {
      this.loader = this.showLoader(this.loader);
      contentResource.get(this.id)
      .then(response => {
       this.institution = response.data;

        /*does thos institution have coordinates?*/
       if(this.coords!=null){
        var selectedLatLng = {
          point: this.coords,
          distance: 10000,
        }
        this.similarInstitutionsDataSet.query.onlyWithLocation = true;
        this.similarInstitutionsDataSet.query.selectedLatLng=selectedLatLng;
      }

      if(this.institution.hasOwnProperty('institution_id')){
        var institutionId=this.institution.institution_id;
        this.getSimilarContents();
        this.getInstitutionOffers(institutionId);
      }
    })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
      });
    }, 
    toggleAppointments(){
      if(this.showAppointments==true){
        this.showAppointments=false;
      }
      else{
        this.showAppointments=true;
        this.showMap=false;
      }
    },
    toggleMap(){
      if(this.showMap==true){
        this.showMap=false;
      }
      else{
        this.showMap=true;
        this.showAppointments=false;
      }
    },
    async getSimilarContents() {
      const { limit, page } = this.similarInstitutionsDataSet.query;
      const { data, meta } = await getContentsOfType(this.$institutionId,this.similarInstitutionsDataSet.query);
      this.similarInstitutionsDataSet.data = data;
      this.similarInstitutionsDataSet.data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.similarInstitutionsDataSet.meta = meta;
      this.similarInstitutionsDataSet.total = meta.total;
    },
    async getInstitutionOffers(institutionId) {
      const { limit, page } = this.contentDataSet.query;
      await getInstitutionContents(institutionId,this.contentDataSet.query).then(response => {
        var data = response.data;
        var meta = response.meta;
        this.contentDataSet.data = data;
        this.contentDataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
        });
        this.contentDataSet.meta = meta;
        this.contentDataSet.total = meta.total;
      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content.detailed .main-title{
    @media (max-width: 760px) {
      top:-120px;
    }

    @media (max-width: 510px) {
      top:-150px;
    }
  }

  .page-content.detailed {
    @media (max-width:991px){
      padding-bottom: 80px;
    }
  }

  .mobile-bookmark-btn{
    display:none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 0;
      position: relative;
      top: -100px;
      z-index: 100;
      height: 0;

      .btn-bookmark{
        margin-right: 10px;
        position: absolute;
        bottom: -120px;
        right: 0;
      }

    }
  }

  .desktop-bookmark-btn{
    @media (max-width: 760px) {
      display:none;
    }
  }
  .page-content.detailed .main-title{
    @media (max-width: 760px) {
      top:-120px;
    }

    @media (max-width: 510px) {
      top:-150px;
    }
  }

  .mobile-bookmark-btn{
    display:none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 0;
      position: relative;
      top: -100px;
      z-index: 100;
      height: 0;

      .btn-bookmark{
        margin-right: 10px;
        position: absolute;
        bottom: -120px;
        right: 0;
      }

    }
  }

  .desktop-bookmark-btn{
    @media (max-width: 760px) {
      display:none;
    }
  }


  #horizontal-style {
  padding-inline-start: 0 !important; // Just in case if you find that there is an extra padding at the start of the line 
  justify-content: space-around;
  display: flex;

  @include responsive($scroll-breakpoint){
    flex-direction: column;
    margin-bottom:10px;
    margin-top:20px;

    li{
      padding-bottom:10px;
    }

    li:last-of-type{
      padding-bottom:0px;
    }
  }

}

.page-content.detailed .info--list li{
  margin-right: 0px;
}

.page-content.detailed .main-title h1{

  @include responsive($scroll-breakpoint){
    max-width: calc(100% - 80px);
  }
}

.content-wrapper{
  padding: 30px 0;

  @include responsive($scroll-breakpoint) {
    padding: 0px;
  }

  .col-12.col-lg-6 .text-wrapper{
    @include responsive($scroll-breakpoint) {
      padding: 30px 0;      
    }
  }

}

.col-lg-6{

  .text-wrapper{
    padding-left: 20px;

    @include responsive($scroll-breakpoint) {
      padding-left: 0px;
    }

  }

  .map-wrapper{

    width: 100%;
    height: 100%;
    padding-right: 20px;

    @include responsive($scroll-breakpoint) {
      padding-right: 0px;
    }

    .map{
      width:100%; 
    }
  }
}

ul.info--list{

  li.info-list{ 

    .btn-link {
      text-align: left;
    }

    a {
      font-family: "Comfortaa", cursive;color: #686B6F;

      &:hover{
        border-color: #E5321B;
        color: #E5321B;
      }
    }

  }
}

.footer-margin{
  @include responsive($scroll-breakpoint) {
    margin-bottom: 0vh;
  }
}

</style>